import { registerApplication, start } from "single-spa";

function showWhenAnyOf(routes) {
  return function (location) {
    return routes.some((route) => location.pathname === route);
  };
}

function showWhenPrefix(routes) {
  return function (location) {
    return routes.some((route) => location.pathname.startsWith(route));
  };
}

function showExcept(routes) {
  return function (location) {
    return !routes.some((route) => location.pathname.startsWith(route));
  };
}

function showHome() {
  return function (location) {
    return location.pathname === '/' || location.pathname.startsWith('/home')
  };
}

registerApplication(
    'global-nav',
    () => import('@Bitreel/portal-global-nav'),
    showExcept(['/login', '/public']),
);

registerApplication(
    'login',
    () => import('@Bitreel/portal-login'),
    showWhenAnyOf(['/login']),
);

registerApplication(
    'home',
    () => import('@Bitreel/portal-home'),
    showHome(),
);


registerApplication(
    'showrooms',
    () => import('@Bitreel/portal-showrooms'),
    showWhenPrefix(['/showrooms']),
);

registerApplication(
    'products',
    () => import('@Bitreel/portal-products'),
    showWhenPrefix(['/products', '/public/products']),
);

start({
  urlRerouteOnly: true,
});
